import React from 'react'
import Mirror from './Mirror'
import Laptop from './Laptop'
import Notebook from './Notebook'
import './Table.css'

export default function Table({MirrorOnClick,LaptopOnClick, NotebookOnClick} ) {
  return (

    <div class="grid-container">
      <div class="grid-item-title">
        <p>Hi, I'm <b>Negin</b>! Welcome to my digital table. I <b>turn big ideas into real products</b>—blending technical expertise, design thinking, and product strategy to create scalable, user-centered solutions.</p>
      </div>
      <div class="grid-item-mirror grid-item-img"><Mirror onClick={MirrorOnClick}/></div>
      <div class="grid-item-laptop grid-item-img"><Laptop onClick={LaptopOnClick}/></div>
      <div class="grid-item-notebook grid-item-img"><Notebook onClick={NotebookOnClick}/> </div>
      <div class="grid-item-mirror-lable grid-item-lable">
        <p className=" mirror-grid-text-color "><b>About Me</b>.</p>
      </div>
      <div class="grid-item-laptop-lable grid-item-lable">
        <p className="laptop-grid-text-color"><b>Case Studies and Projects</b>.</p>
      </div>
      <div class="grid-item-notebook-lable grid-item-lable">
        <p className="notebook-grid-text-color"><b>Contact Me</b>.</p>
      </div>
   
  </div>
  )
}


