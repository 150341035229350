
// Importing dependencies and assets
import React from 'react';
import aboutMeMirrorImage from '../../assets/about-me-mirror.png';
import aboutMeStickerRegular from '../../assets/about-me-sticker-regular.png';
import aboutMeStickerPattern from '../../assets/about-me-sticker-pattern.png';
import './AboutMe.css';

// AboutMe Component: Displays a personal introduction section with images and text
export default function AboutMe() {
  return (
    <div className="about-me__container">
      <section className="about-me__section">
        {/* Left Section: Mirror Image */}
        <div className="about-me__image-container">
          <img
            src={aboutMeMirrorImage}
            alt="Mirror"
            className="about-me__mirror"
          />
        </div>
        {/* Right Section: Stickers and Content */}
        <div className="about-me__content">
          {/* Regular Sticker */}
          <img
            src={aboutMeStickerRegular}
            alt="Regular Sticker"
            className="about-me__sticker about-me__sticker--regular"
          />
          
          {/* Pattern Sticker */}
          <img
            src={aboutMeStickerPattern}
            alt="Pattern Sticker"
            className="about-me__sticker about-me__sticker--pattern"
          />
          

          <div className="about-me__text-content">
          <p className="about-me__title">My Name is Negin</p> 
            <p className="about-me__description">
            My journey into Product Management started with a passion for building and problem-solving. I began my career as a graphic designer, creating user-centric visuals, optimizing client communication, and improving workflows. This experience sparked my interest in UX/UI and frontend development, leading me to transition into full-stack development, where I built MVPs for startups, balancing business needs, user feedback, and technical constraints.
            </p>
            <p className="about-me__description">
            Working as a full-stack and mobile developer, I became deeply involved in feature prioritization, Agile development, and stakeholder communication. I worked closely with clients, engineers, and designers, helping startups define their MVP scope, prioritize features, and iterate based on user research. My exposure to user research, competitive analysis, and product roadmapping made me realize that my true passion lies in Product Management—shaping the strategy, execution, and impact of products.
            </p>
            <p className="about-me__description">
            Now, as a Technical Product Manager, I leverage my technical expertise, Agile mindset, and customer-driven approach to build scalable, efficient, and high-impact products. I thrive in cross-functional teams, facilitating collaboration between engineering, design, and business stakeholders to drive results.
            </p>
          </div>

        </div>
      </section>
    </div>
  );
}

